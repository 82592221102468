<template>
  <div class="authentication-component h-100 w-100 overflow-auto position-fixed">
    <div class="container h-100 d-flex align-items-center">
      <div class="authentication-component-inner m-auto">
        <div class="header_content mt-5 mb-5 text-center clear">
          <div class="logo_block flex justify-center">
            <a class="active">
              <img class="w-48" :src="getSiteDetails.header_logo" alt="replug">
            </a>
          </div>

        </div>
        <div class="white_box m-auto w-75">
          <div class="box_inner">
            <div class="box_head !bg-[#F4F6FA] d-flex align-items-center justify-content-center">
              <div class="left">
                <h2 class="text">Check your email!</h2>
              </div>

            </div>
            <div class="box_content !bg-white p-5">

              <div class="text-center text">
                <p>We have sent you a verification email to your email address.
                  <span class="email font-weight-bold">{{ getProfile.email }}</span>
                  Click and follow the link inside it.</p>
              </div>
                <div class="flex justify-center items-center mt-4">

                  <Button
                    id="login-button"
                    type="button"
                    class="text-white bg-[#2560D7] hover:bg-blue-700 mr-2"
                    buttonClass="btn-lg"
                    @click.prevent="resend()"
                    :disabled="loader"
                  >
                    <template v-slot:label>Resend</template>
                    <template v-if="loader" v-slot:loader>
                      <Loader></Loader>
                    </template>
                  </Button>
                  <Button
                    id="login-button"
                    type="button"
                    class="border border-gray-500 hover:bg-[#F2F3F8]"
                    buttonClass="btn-lg"
                    @click="$router.push('update_email')"
                  >
                    <template v-slot:label>Try with another account</template>
                  </Button>

                </div>

            </div>

          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import http from '@/mixins/http-lib'
import { getWhitelabelDetailsFromDomain } from '@/config/config'
import { userTypes } from '@/state/modules/mutation-types'

export default {
  async created () {
    await this.fetchWhitelabelAppSettings()
  },
  data() {
    return {
      loader: false
    }
  },
  components: {
    Button: () => import('@/ui/ui-kit/v2/Button.vue'),
    Loader: () => import('@/ui/ui-kit/v2/Loader.vue'),
  },
  computed: {
    ...mapGetters(['getProfile'])
  },
  methods: {
    ...mapActions(['fetchWhitelabelAppSettings']),
    async resend() {
      this.loader = true
      await this.resendVerificationEmail()
      this.loader = false
    }
  }
}
</script>
