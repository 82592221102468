<template>
  <div>
    <!-- Display loader meanwhile required data is fetched -->
    <div class="loader-wrapper" v-if="this.platform_loading">
      <img src="/assets/img/replug-loader.svg" alt="loading" title="Loading..." class="logo-loading">
      <p>Getting everything ready...</p>
    </div>
    <div v-if="getProfile._id && getProfile.email_verified == false">
      <email-verify></email-verify>
    </div>
    <template v-else-if="$route.path.includes('/preview/')">
      <router-view></router-view>
    </template>

    <template v-else>
      <div v-if="$route.name === 'codepreview'">
        <transition name="slide">
          <router-view></router-view>
        </transition>
      </div>
      <div class="main_wrapper" v-else>
        <div
          :class="{ activeTourPositionFix: getGuidedTour.step5 }"
          v-if="getGuidedTourOverlay"
          class="guided_overlay_black"
          id="guided_overlay_black"
        ></div>

        <div v-if="getUnauthorizedException" class="disconnected_overlay">
          <div
            class="alert notify_bar alert-dismissable align_center"
            style="position: fixed"
          >
            <div class="notify_content">
              <p>
                You have been disconnected due to inactivity for too long and
                need to reload, <a @click.prevent="reload()">Reload Now</a>
              </p>
            </div>
          </div>
        </div>

        <!--Notificaiton bar for email confirmation -->
        <div
          v-if="getProfile._id && getProfile.email_verified == false"
          class="
            email_notify_bar
            notify_bar
            alert-dismissable
            align_center
            bottom
          "
          style="position: fixed"
        >
          <div class="notify_content">
            <p>
              Please confirm that the email {{ getProfile.email }} belongs to
              you.
              <a @click.prevent="resendVerificationEmail()"
                >Resend Email Verification</a
              >
            </p>
          </div>
        </div>
        <is-offline></is-offline>

        <headerbar v-if="getProfile._id"></headerbar>
<!--        <div  :style="{ width: $route.name === 'dashboard' ? 'calc(100% - 60px)' : 'calc(100% - 0px)' }" class="flex h-full">-->
          <div class="w-full">
              <template>
                <router-view></router-view>
              </template>
          </div>
          <!-- <div>
            <ShortcutSidebar/>
          </div> -->
<!--        </div>-->

      </div>
    </template>
  </div>

</template>
<script>
import Headerbar from './Header.vue'
import Sidebar from './Sidebar.vue'
import IsOffline from '@/misc/IsOffline'
import emailVerify from '@/views/users/EmailVerify'
import { mapActions, mapGetters } from 'vuex'
import { JWT_CHROME_LOCAL_STORAGE } from '@/constants'
import { DARK_PURPLE_LOADER_COLOR } from '@/common/constants'
import { authCheck, getWhitelabelDetailsFromDomain } from '../config/config'
import { userTypes } from '../state/modules/mutation-types'
import http from '@/mixins/http-lib'
import vueCookie from 'vue-cookie'
import { cookieAppDomain, cookieDomain } from '../constants'
import ShortcutSidebar from '@/components/ShortcutSidebar.vue'

export default ({
  data () {
    return {
      platform_loading: true,
      size: '20px',
      color: DARK_PURPLE_LOADER_COLOR
    }
  },
  components: {
    Headerbar,
    Sidebar,
    IsOffline,
    emailVerify,
    ShortcutSidebar
  },

  mounted () {
    try {
      $(document).on('click', function (e) {
      // Tag search dropdown....
        var dropdown_selector = $('.input_search')
        // closing dropdown on body click.....
        if (!dropdown_selector.is(e.target) && dropdown_selector.has(e.target).length === 0) {
          $('.search_dd').removeClass('open')
        }
        // tag search dropdown ---End ---
        var marge_tooltip = $('.tag_option')
        // closing setting tag tooltip on body click.....
        if (!marge_tooltip.is(e.target) && marge_tooltip.has(e.target).length === 0) {
          $('.marge_tooltip').removeClass('animate_it')
        }
      })
      this.$root.$on('open-apply-utm-modal', () => {
      // $('#applyUTMModal').modal('show')
        this.$bvModal.show('applyUTMModal')
      })
    } catch (e) {

    }
  },

  async created () {
    // Access workspaceId from route params
     const workspace = await this.fetchWorkspaceById(this.$route.params.workspaceId)

    if (this.$route.query && this.$route.query.token) {
      localStorage.setItem(JWT_CHROME_LOCAL_STORAGE, this.$route.query.token)
      vueCookie.set(JWT_CHROME_LOCAL_STORAGE, this.$route.query.token, { expires: '1Y', domain: cookieDomain })
      vueCookie.set(JWT_CHROME_LOCAL_STORAGE, this.$route.query.token, { expires: '1Y', domain: cookieAppDomain })
      this.$router.push({ name: 'dashboard', params: {workspaceId: workspace.data.data._id} })
    }

    /**
     * If query params contain Frill.co SSO
     * Authenticate user and redirect to frill redirect URI
     */
    const postData = this.getFrillParams()

    const isAuth = await this.authCheck(postData)
    if (!isAuth) {
      this.platform_loading = false
      await this.$router.push({ name: 'login' })
      return
    }

    await this.getUserProfile(true)
    await this.fetchWhitelabelAppSettings()
    if(!this.getSiteDetails.isWhiteLabel) {
      await this.fetchWhiteLabel()
    }

    await this.pushToCurrentRoute(this.$route.name === 'home' ? 'dashboard' : '')

    await this.fetchNotifications()
    await this.setDefaultDomainEnv()
    this.platform_loading = false
  },

  computed: {
    ...mapGetters(['getGuidedTourOverlay', 'getUnauthorizedException', 'getProfile', 'getDomains'])
  },

  methods: {
    ...mapActions([
      'setBrand',
      'setCreation',
      'fetchNotifications',
      'setDefaultDomainEnv',
      'fetchWhitelabelAppSettings',
      'fetchWhiteLabel',
      'fetchWorkspaceById'
    ]),
    /**
     * Method is responsible for checking query params and populate an object
     */
    getFrillParams () {
      const postData = {}
      if (this.$route.query.source) {
        postData['source'] = this.$route.query.source
      }

      if (this.$route.query.redirect) {
        postData['redirect'] = this.$route.query.redirect
      }
      return postData
    },
    reload () {
      this.$router.go({
        path: this.$router.path,
        query: {
          t: +new Date()
        }
      })
    },
    async authCheck (data) {
      await this.$store.dispatch('setWhitelabelLoader', true)
      return await http.post(authCheck, data).then(
        res => {
          /**
           * If login is request from SSO - Frill.co, redirect user
           */
          if (res.data.redirect) {
            window.location.href = res.data.redirect
          }
          this.$store.dispatch('setWhitelabelLoader', false)
          return res.data.status
        },
        err => {
          this.$store.dispatch('setWhitelabelLoader', false)
          return false
        }
      )
    }
  }
})
</script>
